<script lang="ts" setup>
import {
  Button,
  Form,
  FormLayout,
  TextInput,
  Heading,
  HeadingElement,
  HeadingSize,
  TextContainer,
  TextContainerSpacing,
  Banner,
  BannerVariant,
} from '@app/customer/Components';
import { AppLayout } from '@app/customer/Layouts';
import { useForm, usePage } from '@inertiajs/vue3';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import type { PagePropsViewModel } from './types';

const props = defineProps<PagePropsViewModel>();

const form = useForm({
  order_number: props.orderNumber,
  validation_input: props.validationInput,
});

function onFormSubmit(): void {
  form.post(route('customer.intent.store'));
}

const nextPageUrl = computed((): string | null => {
  if (!props.returnOrderIntentId) {
    return null;
  }

  return route('customer.display-order', props.returnOrderIntentId);
});

function openTestOrder(): void {
  const email = encodeURIComponent(usePage().props.ownerEmail as string);

  window.location.href = `?order_number=ORD-0001&email=${email}&skip-order-check=1`;
}

const buttonColor = useBrandColor();
</script>

<template>
  <AppLayout :next-page-url="nextPageUrl">
    <div class="max-w-xl mx-auto">
      <TextContainer
        text-center
        :spacing="TextContainerSpacing.ExtraLoose"
      >
        <Heading
          :element="HeadingElement.H1"
          :size="HeadingSize.Large"
        >
          {{ title }}
        </Heading>

        <div class="prose text-sm">
          <p v-html="introText" />
        </div>
      </TextContainer>

      <div
        v-if="$page.props.errors.integration_exception"
        class="mt-6 md:mt-12"
      >
        <Banner :variant="BannerVariant.Critical">
          <i18n-t
            keypath="customer.form:platform-connection-not-finished"
            for="customer.form:platform-connection-not-finished"
          >
            <template #contact>
              <a
                href="mailto:support@returnless.com"
                class="underline"
              >{{ $t('customer.form:contact') }}</a>
            </template>
          </i18n-t>
        </Banner>
      </div>

      <div
        v-if="$page.props.errors.integration_error"
        class="mt-6 md:mt-12"
      >
        <Banner :variant="BannerVariant.Critical">
          <i18n-t
            keypath="customer.form:unable-to-process-request"
            for="customer.form:unable-to-process-request"
          >
            <template #contact>
              <a
                href="mailto:support@returnless.com"
                class="underline"
              >{{ $t('customer.form:contact') }}</a>
            </template>
          </i18n-t>
        </Banner>
      </div>

      <div
        v-if="!isVirtual"
        class="mt-8 md:mt-20 break-all"
      >
        <div
          v-if="props.status === 'failed'"
          class="mb-8 md:-mt-12"
        >
          <Banner :variant="BannerVariant.Critical">
            {{ $t('customer.global:order-not-found') }}
          </Banner>
        </div>

        <Form @submit="onFormSubmit">
          <FormLayout>
            <TextInput
              v-model="form.order_number"
              :error="form.errors.order_number"
              :label="orderFieldLabel"
              :placeholder="orderFieldPlaceholder"
              name="order_number"
              required
            />

            <TextInput
              v-if="validationMethod === 'email'"
              v-model="form.validation_input"
              :error="form.errors.validation_input"
              :label="$t('customer.form:email:label')"
              :placeholder="$t('customer.form:email:placeholder')"
              name="validation_input"
              required
              type="email"
            />

            <TextInput
              v-if="validationMethod === 'postcode'"
              v-model="form.validation_input"
              :error="form.errors.validation_input"
              :label="$t('customer.form:postcode:label')"
              :placeholder="$t('customer.form:postcode:placeholder')"
              name="validation_input"
              required
              type="text"
            />

            <TextInput
              v-if="validationMethod === 'customerId'"
              v-model="form.validation_input"
              :error="form.errors.validation_input"
              :label="$t('customer.form:external-customer-id:label')"
              :placeholder="$t('customer.form:external-customer-id:placeholder')"
              name="validation_input"
              required
              type="text"
            />

            <Button
              :color="buttonColor"
              type="submit"
              :loading="form.processing"
            >
              <div class="flex items-center space-x-1">
                <MagnifyingGlassIcon class="h-4 w-4" />
                <span>{{ searchButtonLabel }}</span>
              </div>
            </Button>
            <Button
              v-if="hasDemoIntegration"
              @click="openTestOrder"
            >
              {{ $t('customer.form:open-test-order-button') }}
            </Button>
          </FormLayout>

          <div
            v-if="hasDemoIntegration"
            class="mt-12 w-full"
          >
            <div class="w-40 h-40  mx-auto">
              <img
                :src="$props.qrUrl"
                alt="Form QR code"
                loading="lazy"
              >
            </div>
          </div>
        </Form>
      </div>
    </div>
  </AppLayout>
</template>
